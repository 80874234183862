import moment from 'moment'
import auth from '@/core/auth'

let items = {}

/**
 * This object has been created to save user's filter selection on the table.
 * This object is used by "paginationSource.js". You shouldn't use this object
 * from anywehere expect paginationSource. "store.js" and "paginationSource.js"
 * work together.
 *
 * To clear old cache, you should pass the last acceptable date for the pagination
 * source. For example;
 *
 * const source = new PaginationSource('experts', {}, '2020-10-10')
 *
 * With this defination, the old cache values (before 2020-10-10) will be deleted.
 * With that we hope that users will not get any error on the production.
 */
export default {
  init () {
    if (localStorage.store) {
      items = JSON.parse(localStorage.store)
    }
  },

  clearOldCaches (key, date) {
    key = `${auth && auth.user ? auth.user.id : 'annonym'}:${key}`
    if (items[key]) {
      if (moment(items[key].initAt).isBefore(moment(date))) {
        delete items[key]
      }
    }
  },

  set (key, value) {
    key = `${auth && auth.user ? auth.user.id : 'annonym'}:${key}`

    if (items[key]) {
      items[key].value = value
    } else {
      items[key] = {
        initAt: new Date(),
        value
      }
    }

    // We shouldn't keep any data on the local storage because different users
    // can use the same computer. Users should be able to data only when they
    // have permission to see the data. That's why we shouldn't keep it on the
    // local store.
    const clean = JSON.parse(JSON.stringify(items))
    for (const key in clean) {
      clean[key].value.items = []
    }

    // We can keep it on the local storage to use later.
    try {
      localStorage.store = JSON.stringify(clean)
    } catch (err) {
      console.error(err)
    }
  },

  get (key) {
    key = `${auth && auth.user ? auth.user.id : 'annonym'}:${key}`
    if (items[key]) {
      return items[key].value
    }

    return null
  }
}
