const firstTimeModals = [{
  uuid: 'offers',
  title: 'Angebote',
  src: 'images/helper-modal/offer.svg',
  htmltext: `
    <p class="modal-alert-like__first-time-description">
    Hier können Sie mit wenigen Klicks ganz einfach <strong>Angebote</strong> erstellen.
    </p>
    <h3 class="modal-alert-like__tip-title">Tipps</h3>
    <ul class="modal-alert-like__content-list">
      <li>
        Für ein ordnungsgemäßes Angebot empfehlen wir Ihnen, Ihre <strong>Geschäftsangaben</strong> vollständig zu hinterlegen. Diese finden Sie unter <a target="_blank" href="/#/workspace/settings">Stammdaten &rarr; Geschäftsangaben</a>.
      </li>
      <li>
        Arbeiten Sie mit den verschiedenen <strong>Status</strong>, um Ihre Angebote besser zu organisieren. Welches Angebot wurde noch nicht gesendet? Welche Angebote sind schon länger offen?
      </li>
      <li>
      Benutzen Sie <strong>Textvorlagen</strong> und <strong>Artikel</strong>, um Angebote schneller zu erstellen. Textvorlagen können Sie unter <a target="_blank" href="/#/settings/base-data/text-snippets">Stammdaten &rarr; Textvorlagen</a> verwalten. Artikel verwalten Sie unter <a target="_blank" href="/#/settings/base-data/products">Stammdaten &rarr; Artikel</a>.
      </li>
    </ul>
      `
}, {
  uuid: 'deals',
  title: 'Anfragen',
  src: 'images/helper-modal/deal.svg',
  htmltext: `
  <p class="modal-alert-like__first-time-description">
  Hier können Sie Ihre <strong>Anfragen</strong> verwalten.
  </p>
  <h3 class="modal-alert-like__tip-title">Tipps</h3>
  <ul class="modal-alert-like__content-list">
    <li>
      Mit unterschiedlichen <strong>Anfrage-Phasen</strong> (z.B. Kontaktiert, Angebot gesendet) behalten Sie den Überblick über Ihre Anfragen.
    </li>
    <li>
      Sie können zu Anfragen <strong>Ereignisse</strong> anlegen (z.B. Telefongespräche). Damit wissen Sie und Ihre Teammitglieder immer, was mit den Kunden abgesprochen wurde.
    </li>
    <li>
      Sie können <strong>Umsatzerwartungen</strong> zu Ihren Anfragen angeben und diese danach priorisieren.
    </li>
  </ul>
    `
}, {
  uuid: 'reviews',
  title: 'Kundenbewertungen',
  src: 'images/helper-modal/reviews.svg',
  htmltext: `
  <p class="modal-alert-like__first-time-description">
    Auf dieser Seite können Sie Ihre <strong>Kundenbewertungen</strong> verwalten, die Sie für Ihr Unternehmen erhalten.
  </p>
  <h3 class="modal-alert-like__tip-title">Tipps</h3>
  <ul class="modal-alert-like__content-list">
    <li>
      <strong>Kundenbewertungen</strong> erhöhen die Wahrscheinlichkeit, dass Sie von Interessenten kontaktiert werden. Laden Sie <strong>Kunden und Geschäftspartnern</strong> ein, Sie zu bewerten (mit dem grünen Button "+ Bewertung einholen").
    </li>
    <li>
      <strong>Antworten</strong> Sie möglichst auf jede Bewertung. Das zeigt, dass Ihnen die Bewertungen Ihrer Kunden wichtig sind.
    </li>
    <li>
      <strong>Kritische Bewertungen</strong> gehören zum Geschäftsleben dazu. Doch wie antworten Sie am besten auf negative Bewertungen? Erfahren Sie hier mehr dazu:
      <a class="modal-alert-like__content-list__ext-link" href="#">
        [Link]
        <svg class="modal-alert-like__content-list__ext-link__icon">
          <use xlink:href="/images/sprite.svg#icon-external-link" />
        </svg>
      </a>
    </li>
  </ul>
    `
}, {
  uuid: 'resubmission',
  title: 'Wiedervorlagen',
  src: 'images/helper-modal/resubmission.svg',
  htmltext: `
  <p class="modal-alert-like__first-time-description" style="margin-bottom: 0;">
    <strong>Wiedervorlagen</strong> eignen sich gut, um Sie nach einer gewissen Zeit an etwas zu erinnern. <strong>Beispiel:</strong> Ein Kunde ist prinzipiell an Ihren Leistungen interessiert, möchte aber diesbezüglich erst in 2 Monaten nochmal kontaktiert werden.<br><br>
    Sie können Wiedervorlagen auch als <strong>Aufgaben für die Zukunft</strong> sehen, die Sie nicht sofort erledigen können. Wiedervorlagen können Sie für Kontakte und Vorgänge wie Anfragen, Angebote und Rechnungen setzen.
  </p>
    `
}, {
  uuid: 'articles',
  title: 'Artikel',
  src: 'images/helper-modal/products.svg',
  htmltext: `
  <p class="modal-alert-like__first-time-description" style="margin-bottom: 0;">
  Sie können Ihre <strong>Artikel</strong> als Stammdaten anlegen und bei der Erstellung von Angeboten, Aufträgen und Rechnungen mit wenigen Klicks einfügen. Dies erspart Ihnen viel Zeit, da Sie Artikelpreise und -beschreibungen nur einmal schreiben müssen.<br><br>
  Artikel können Ihre <strong>Dienstleistungen</strong>, aber auch <strong>Verkaufsartikel</strong> sein.
  </p>
    `
}
]

export default firstTimeModals
