import moment from 'moment'

export default function (value, long = true) {
  if (value === undefined || value === null) {
    return null
  }
  if (long) {
    return moment(value).format('DD.MM.YYYY HH:mm')
  } else {
    return moment(value).format('DD.MM.YY HH:mm')
  }
}
