import Vue from 'vue'
import auth from '@/core/auth'
import rest from '@/core/rest'

const ServerCache = new Vue({
  data: {
    items: {},
    id: null,
    initted: false
  },
  methods: {
    init () {
      return new Promise(resolve => {
        auth.whenReady(() => {
          rest.get('user/cache/', {}, (response) => {
            this.items = JSON.parse(response.cache)
            this.id = response.id
            this.initted = true
            resolve()
          })
        })
      })
    },

    async get (key) {
      if (!this.initted) {
        await this.init()
      }
      if (!key) {
        return this.items
      }
      return this.items[key]
    },

    async set (key, value) {
      if (!this.initted) {
        await this.init()
      }
      this.items[key] = value
      this.store()
    },

    async save (data, cb) {
      if (!this.initted) {
        await this.init()
      }
      for (const key in data) {
        this.items[key] = data[key]
      }
      this.store(cb)
    },

    clear () {
      this.items = {}
      this.initted = null
      this.id = null
    },

    store (cb) {
      const data = {
        cache: JSON.stringify(this.items)
      }
      if (this.id) {
        rest.put('user/cache/' + this.id, data, () => {
          if (cb) {
            cb()
          }
        })
      } else {
        rest.post('user/cache/', data, () => {
          if (cb) {
            cb()
          }
        })
      }
    }
  }
})

ServerCache.install = function () {
  Object.defineProperty(Vue.prototype, '$serverCache', {
    get () { return ServerCache }
  })
}

export default ServerCache
