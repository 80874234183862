import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/core/auth'
import events from '@/core/events'
import hashTracker from '@/core/hashTracker'
// import socket from '@/core/socket'
import staticProjectData from '@/core/staticProjectData'
import permission from '@/core/permitted'
import modal from '@/core/modal'

// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

var router = new Router({
  routes: [
    {
      path: '/auth',
      name: 'auth',
      meta: { guest: true },
      component: () => import('./views/containers/AuthContainer.vue'),
      children: [
        {
          path: 'login/:token?/:email?',
          name: 'login',
          component: () => import('./views/auth/Login.vue'),
          meta: { guest: true }
        },
        {
          path: 'signup/:email?',
          name: 'signup',
          component: () => import('./views/auth/Signup.vue'),
          meta: { guest: true }
        },
        {
          path: 'refresh-confirmation-link',
          name: 'refreshConfirmationLink',
          component: () => import('./views/auth/RefreshConfirmationLink.vue'),
          meta: { guest: true }
        },
        {
          path: 'renew',
          name: 'renew',
          component: () => import('./views/auth/Renew.vue'),
          meta: { guest: true }
        },
        {
          path: 'set-new-password/:token',
          name: 'setNewPassword',
          component: () => import('./views/auth/ChangePassword.vue'),
          meta: { guest: true }
        },
        {
          path: 'forgot-password/:msg?/:email?',
          name: 'forgotPassword',
          component: () => import('./views/auth/ForgotPassword.vue'),
          meta: { guest: true }
        },
        // {
        //   path: '/business-listing-home',
        //   name: 'businessListingHome',
        //   component: () => import('./views/customer/business-listings/BusinessListingHome.vue'),
        //   meta: {
        //     title: 'Business Listing Home',
        //     guest: true
        //   }
        // },
        // {
        //   path: '/business-companies',
        //   name: 'businessListingCompanies',
        //   component: () => import('./views/customer/business-listings/BusinessListingCompanies.vue'),
        //   meta: {
        //     title: 'Business Listing Companies',
        //     guest: true
        //   }
        // },
        {
          path: '/business-listings',
          name: 'businessListingList',
          component: () => import('./views/customer/business-listings/BusinessListingList.vue'),
          meta: {
            title: 'Business Eintrag',
            guest: true
          }
        },
        {
          path: '/business-listings/:workspaceId/preview',
          name: 'businessListingPreview',
          component: () => import('./views/customer/business-listings/BusinessListingPreview.vue'),
          meta: {
            title: 'Business Eintrag',
            guest: true
          }
        },
        {
          path: '/business-listings/:workspaceId',
          name: 'businessListingDetail',
          component: () => import('./views/customer/business-listings/BusinessListingDetail.vue'),
          meta: {
            title: 'Business Eintrag',
            guest: true
          }
        }
      ]
    },
    {
      path: '/',
      name: 'dashboard',
      component: () => import('./views/containers/DashboardContainer.vue'),
      children: [
        // We have two same route because of this: https://github.com/vuejs/vue-router/issues/724
        {
          path: '/home',
          name: 'home',
          component: () => import('./views/Home.vue'),
          meta: {
            title: 'Dashboard'
          }
        },
        {
          path: '/contacts',
          name: 'contacts',
          component: () => import('./views/contacts/ContactList.vue'),
          meta: {
            title: 'Kontakte'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_ALL_CONTACTS')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/customer/offers',
          name: 'customerOffers',
          component: () => import('./views/customer/offers/Table.vue'),
          meta: {
            title: 'Meine Angebote'
          }
        },
        {
          path: '/customer/offers/:id',
          name: 'customerOfferDetail',
          component: () => import('./views/customer/offers/Detail.vue'),
          meta: {
            title: 'Angebotinformationen',
            guest: true
          }
        },
        {
          path: '/verification/:id',
          name: 'verificationDetail',
          component: () => import('./views/verifications/Detail.vue'),
          beforeEnter: async (to, from, next) => {
            if (auth && auth.user.isSpecialWorker && auth.hasPermitted(['EDIT_VERIFICATIONS'])) {
              return next()
            }
            router.push('/')
          },
          meta: {
            title: 'Verifizierungsdetails'
          }
        },
        {
          path: '/verifications',
          name: 'verifications',
          component: () => import('./views/verifications/VerificationList.vue'),
          beforeEnter: async (to, from, next) => {
            if (auth && auth.user.isSpecialWorker && auth.hasPermitted(['EDIT_VERIFICATIONS'])) {
              return next()
            }
            router.push('/')
          },
          meta: {
            title: 'Verifizierungen'
          }
        },
        {
          path: '/network-list',
          name: 'network_list',
          component: () => import('./views/network/NetworkList.vue'),
          meta: {
            title: 'Netzwerk'
          }
        },
        {
          path: '/business-listing-verifications',
          name: 'businessListingVerifications',
          component: () => import('./views/business-listings/ListingVerificationList.vue'),
          meta: {
            title: 'Publikationen'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('MANAGE_BUSINESS_LISTINGS')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/customer-satisfactions/:all?',
          name: 'customerSatisfactions',
          // component: () => import('./views/user/settings/workspace/CustomerSatisfactions.vue'),
          component: () => import('./views/business-listings/CustomerSatisfactionList.vue'),
          meta: {
            title: 'Kundenbewertungen'
          }
          // beforeEnter: async (to, from, next) => {
          //   if (await permission.check('EDIT_VERIFICATIONS')) {
          //     next()
          //   } else {
          //     router.push('/')
          //   }
          // }
        },
        {
          path: '/statistics-old/:id?',
          name: 'statistics-old',
          component: () => import('./views/qm/StatisticsOld.vue'),
          meta: {
            title: 'Statistiken-alt'
          }
        },
        {
          path: '/statistics/:id?',
          name: 'statistics',
          component: () => import('./views/qm/Statistics.vue'),
          meta: {
            title: 'Statistiken'
          }
        },
        {
          path: '/statistic-graphics/:id?',
          name: 'statistic-graphics',
          component: () => import('./views/qm/StatisticGraphics.vue'),
          meta: {
            title: 'Statistik Graphiken'
          }
        },
        {
          path: '/custom-things/expert-teams',
          name: 'expert_team_list',
          // component: () => import('./views/network/ExpertTeamList.vue'),
          component: () => import('./views/qm/CustomThings.vue'),
          meta: {
            title: 'Auflistung'
          },
          beforeEnter: async (to, from, next) => {
            if (auth.user && auth.user.isSpecialWorker) {
              return next()
            }
            router.push('/')
          }
        },
        {
          path: '/custom-things/:type/:subtype?',
          name: 'custom-things',
          component: () => import('./views/qm/CustomThings.vue'),
          meta: {
            title: 'Statistik'
          },
          beforeEnter: async (to, from, next) => {
            if (auth.user && (auth.user.isSpecialWorker || auth.user.id === 199)) {
              return next()
            }
            router.push('/')
          }
        },
        {
          path: '/custom-things2/:type/:subtype?',
          name: 'custom-things2',
          component: () => import('./views/qm/CustomThings.vue'),
          meta: {
            title: 'QM & QS'
          },
          beforeEnter: async (to, from, next) => {
            if (auth.user && (auth.user.isSpecialWorker || auth.user.id === 199)) {
              return next()
            }
            router.push('/')
          }
        },
        {
          path: '/settings/team/:id/settings/limits',
          name: 'expertlimitslist',
          component: () => import('./views/settings/teams/tabs/ExpertLimitsList.vue'),
          meta: {
            title: 'Sperren / Entsperren'
          },
          beforeEnter: async (to, from, next) => {
            if (auth.isAdmin || auth.hasPermitted(['SHOW_EXPERT_LIMIT_LIST'], auth.user.selectedWorkspace.id)) {
              return next()
            }
            router.push('/')
          }
        },
        {
          path: '/settings/team/:id/settings/verifications',
          name: 'serviceVerifications',
          component: () => import('./views/settings/teams/tabs/Verifications.vue'),
          meta: {
            title: 'Leistungsverifizierung'
          },
          beforeEnter: async (to, from, next) => {
            if (auth.isAdmin || auth.hasPermitted(['SHOW_VERIFICATIONS_DETAIL'], auth.user.selectedWorkspace.id)) {
              return next()
            }
            router.push('/')
          }
        },
        {
          path: '/surveys/:id',
          name: 'surveys',
          component: () => import('./views/surveys/Survey.vue'),
          meta: {
            title: 'Umfragen'
          }
        },
        {
          path: '/profile/settings/:type?/:children?',
          name: 'profileSettings',
          component: () => import('./views/user/Settings.vue'),
          meta: {
            title: 'Profil Einstellungen'
          }
        },
        {
          path: '/profile/:id?',
          name: 'profile',
          component: () => import('./views/user/Profile.vue'),
          meta: {
            title: 'Profil'
          }
        },
        {
          path: '/team-member-profile/:id',
          name: 'teamMemberProfile',
          component: () => import('./views/user/TeamMemberProfile.vue'),
          meta: {
            title: 'Mitglieds-Profil'
          }
        },
        {
          path: '/team-application-detail/:id',
          name: 'teamApplicationDetail',
          component: () => import('./views/user/TeamApplicationDetail.vue'),
          meta: {
            title: 'Bewerber'
          }
        },
        // {
        //   path: '/company/:id',
        //   name: 'companyProfile',
        //   component: () => import('./views/user/CompanyProfile.vue'),
        //   meta: {
        //     title: 'Profil'
        //   }
        // },
        {
          path: '/profile/:id',
          name: 'publicProfile',
          component: () => import('./views/user/Profile.vue'),
          meta: {
            title: 'Profil'
          }
        },
        // https://pixelkultur.atlassian.net/browse/V2-163
        {
          path: '/offers',
          name: 'offers',
          component: () => import('./views/offers/OfferList.vue'),
          props: true,
          meta: {
            title: 'Angebote'
          }
        },
        {
          path: '/offer/:id?/:draft?',
          name: 'offer',
          component: () => import('./views/offers/OfferDashboard.vue'),
          meta: {
            title: 'Angebot'
          }
        },
        {
          path: '/bills/:type?',
          name: 'bills',
          component: () => import('./views/bills/BillList.vue'),
          meta: {
            title: 'Rechnungen'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_BILLS')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/qm/:type/lost',
          name: 'lostWorks',
          component: () => import('./views/qm/LostAwardList.vue'),
          beforeEnter: async (to, from, next) => {
            if (to.params.type === 'award') {
              if (await permission.check('SHOW_AWARD_MENU')) {
                next()
              } else {
                router.push('/')
              }
            } else {
              next()
            }
          },
          meta: {
            title: 'Verkauf'
          }
        },
        {
          path: '/works/:type/:subtype?',
          name: 'works',
          props: true,
          component: () => import('./views/work/WorkList.vue'),
          beforeEnter: async (to, from, next) => {
            if (to.params.type === 'award') {
              if (await permission.check('SHOW_AWARD_MENU')) {
                next()
              } else {
                router.push('/')
              }
            } else {
              next()
            }
          },
          meta: {
            title: 'Verkauf'
          }
        },
        {
          path: '/workspace/settings',
          name: 'workspaceSettings',
          component: () => import('./views/workspace/WorkspaceSettings.vue'),
          meta: {
            title: 'Einstellungen'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('EDIT_WORKSPACE_SETTINGS')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/workspace/workspace-settings',
          name: 'settings',
          component: () => import('./views/workspace/Settings.vue'),
          meta: {
            title: 'Einstellungen'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('EDIT_WORKSPACE_SETTINGS')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/workspace/user-management',
          name: 'workspaceUserManagement',
          component: () => import('./views/workspace/UserManagement.vue'),
          meta: {
            title: 'Benutzerverwaltung'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_WORKERS_DETAIL')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        // {
        //   path: '/workspace/business-listing',
        //   name: 'businessListing',
        //   component: () => import('./views/business-listings/BusinessListing.vue'),
        //   meta: {
        //     title: 'Business Listing'
        //   }
        // },
        {
          path: '/listing-settings/:workspaceId?',
          name: 'listingSettings',
          props: true,
          component: () => import('./views/business-listings/ListingSettings.vue'),
          meta: {
            title: 'Business Listing Einstellungen'
          },
          beforeEnter: async (to, from, next) => {
            if ((!to.params.workspaceId && (await permission.check('EDIT_BUSINESS_LISTING'))) || (to.params.workspaceId && (await permission.check('MANAGE_BUSINESS_LISTINGS')))) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/review/:id',
          name: 'review',
          component: () => import('./views/reviews/ReviewDashboard.vue'),
          meta: {
            title: 'Business Eintrag Kundenbewertung'
          }
        },
        {
          path: '/list-members/:type/:id',
          name: 'listMembers',
          component: () => import('./views/user/UserList.vue'),
          meta: {
            title: 'Partnerliste'
          }
        },
        {
          path: '/list-applications/:id',
          name: 'listApplications',
          component: () => import('./views/settings/teams/tabs/TeamApplicationList.vue'),
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_COORPERATIONS_PARTNER_DETAIL')) {
              next()
            } else {
              router.push('/')
            }
          },
          meta: {
            title: 'Partnerliste'
          },
          props: true
        },
        {
          path: '/qm2/:type',
          name: 'reportedAwardsOld',
          component: () => import('./views/qm/ReportedAwardsListOld.vue'),
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_QUALITY_MANAGER_MENU')) {
              next()
            } else {
              router.push('/')
            }
          },
          meta: {
            title: 'Verkauf'
          }
        },
        {
          path: '/qm/:type/:id',
          name: 'reportedAwards',
          component: () => import('./views/qm/ReportedAwardsList.vue'),
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_QUALITY_MANAGER_MENU')) {
              next()
            } else {
              router.push('/')
            }
          },
          meta: {
            title: 'Verkauf'
          }
        },
        {
          path: '/work/:id',
          name: 'work',
          component: () => import('./views/work/WorkDashboard.vue')
        },
        {
          path: '/downloads/:id',
          name: 'downloads',
          component: () => import('./views/downloads/DownloadsList.vue'),
          meta: {
            title: 'Downloads'
          }
        },
        {
          path: '/projects',
          name: 'projects',
          component: () => import('./views/projects/ProjectList.vue'),
          beforeEnter: async (to, from, next) => {
            if (auth.user.isBxpUser /* await permission.check('SHOW_PROTOCOL_MENU') */) {
              await staticProjectData.loadAll()
              next()
            } else {
              router.push('/')
            }
          },
          meta: {
            title: 'Protokoll'
          }
        },
        {
          path: '/project/:id',
          name: 'project',
          component: () => import('./views/projects/ProjectDashboard.vue'),
          beforeEnter: async (to, from, next) => {
            await staticProjectData.loadAll()
            next()
          },
          meta: {
            title: 'Projekt'
          }
        },
        {
          path: '/support/:page?',
          name: 'support',
          component: () => import('./views/support/Support.vue'),
          beforeEnter: async (to, from, next) => {
            await staticProjectData.loadAll()
            next()
          },
          meta: {
            title: 'Hilfe & Support'
          }
        },
        {
          path: '/account/plan',
          name: 'plan',
          component: () => import('./views/account/price/Plan.vue'),
          meta: {
            title: 'Tarif'
          }
        },
        {
          path: '/account/select-plan',
          name: 'select-plan',
          component: () => import('./views/account/price/select-plan.vue'),
          meta: {
            title: 'Tarif auswählen'
          }
        },
        {
          path: '/settings/team/:id/details',
          name: 'team-detail',
          component: () => import('./views/settings/teams/TeamDetail.vue'),
          meta: {
            title: 'Business Team Detail'
          }
        },
        {
          path: '/settings/team/:id/join/:tab?',
          name: 'team-join',
          component: () => import('./views/settings/teams/TeamJoin.vue'),
          meta: {
            title: 'Business Team Bewerbung'
          }
        },
        {
          path: '/settings/team/:id/settings/:type?',
          name: 'groupSettings',
          component: () => import('./views/settings/teams/TeamSettings.vue'),
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_COORPERATIONS_PARTNER_DETAIL')) {
              next()
            } else {
              router.push('/')
            }
          },
          meta: {
            title: 'Business Team Informationen'
          }
        },
        {
          path: '/settings/base-data',
          name: 'baseDatas',
          component: () => import('./views/settings/base-data/Index.vue'),
          meta: {
            title: 'Stammdaten'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_STAMMDATEN_MENU')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/settings/base-data/products',
          name: 'baseDataProducts',
          component: () => import('./views/settings/base-data/ProductList.vue'),
          meta: {
            title: 'Artikel'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_STAMMDATEN_MENU')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/settings/base-data/services',
          name: 'baseDataServices',
          component: () => import('./views/settings/base-data/ServiceList.vue'),
          meta: {
            title: 'Auftragsarten'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_STAMMDATEN_MENU')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/settings/base-data/text-snippets',
          name: 'baseDataTextSnippets',
          component: () => import('./views/settings/base-data/TextSnippetList.vue'),
          meta: {
            title: 'Textvorlagen'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_STAMMDATEN_MENU')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/settings/teams',
          name: 'teams',
          component: () => import('./views/settings/teams/TeamList.vue'),
          meta: {
            title: 'Business Teams'
          }
        },
        {
          path: '/settings/surveys',
          name: 'surveyList',
          component: () => import('./views/settings/questionGroups/SurveyList.vue'),
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_SURVEYS_MENU') || auth.isAdmin()) {
              next()
            } else {
              router.push('/')
            }
          },
          meta: {
            title: 'Umfragen'
          }
        },
        {
          path: '/settings/survey-groups/:id',
          name: 'questions',
          component: () => import('./views/settings/questionGroups/SurveyGroupSettings.vue'),
          meta: {
            title: 'Umfragen Details'
          }
        },
        {
          path: '/baseData/:type',
          name: 'baseData',
          component: () => import('./views/baseData/BaseDataList.vue'),
          meta: {
            title: 'Stammdaten'
          }
        },
        {
          path: '/tasks/:id?',
          name: 'tasks',
          component: () => import('./views/tasks/TaskList.vue'),
          meta: {
            title: 'Aufgaben'
          }
        },
        {
          path: '/resubmissions',
          name: 'resubmissions',
          component: () => import('./views/resubmissions/ResubmissionList.vue'),
          meta: {
            title: 'Wiedervorlagen'
          }
        },
        {
          path: '/appointments',
          name: 'appointments',
          component: () => import('./views/appointments/AppointmentList.vue'),
          meta: {
            title: 'Termine'
          }
        },
        {
          path: '/administration',
          name: 'administration',
          component: () => import('./views/administration/Index.vue'),
          meta: {
            title: 'Administration'
          }
        },
        {
          path: '/administration/users',
          name: 'administration_users',
          component: () => import('./views/administration/users/UserList.vue'),
          meta: {
            title: 'User List'
          }
        },
        {
          path: '/chats',
          name: 'chats',
          component: () => import('./views/user/Chats.vue'),
          meta: {
            title: 'Chats'
          }
        },
        {
          path: '*',
          name: 'home2',
          component: () => import('./views/Home.vue'),
          meta: {
            title: 'Sachverständige'
          }
        }
      ]
    }
  ]
})

// We should detect if the user click the prev button of the browser.
window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})

router.beforeEach((to, from, next) => {
  // If the developer used hash trackter for form changes, we should validate
  // if everything is same or not
  if (hashTracker.initial !== hashTracker.current) {
    // If there are some unsaved data, we should ask to the user what should we do
    hashTracker.askConfirmation(
      // Save the form and go to the next route
      async () => {
        // We should set the "what-happend-after-save".
        hashTracker.setRelease(() => {
          // We should clear the hash tracker data
          hashTracker.clear()
          // And redirect to the next route
          next()
        })
        // Call the save method
        await hashTracker.method()
      },
      // Cancel and back to the form
      () => {
        return next(false)
      },
      // Discard
      () => {
        // We should clear the data
        hashTracker.clear()
        // We don't need to do anything.
        next()
      }
    )

    return
  }

  // We should clear the hash tracker if everything is ok
  hashTracker.clear()

  // If the user hasn't clicked the back button of the browser, we shouldn't close
  // the modals. It could be a manual route trigger (this.$router.push()).
  if (window.popStateDetected) {
    // We should reset the value of back button tracker.
    window.popStateDetected = false
    // if we're in a modal, we should close the modal instead of changing route
    // TODO: we should replace our $swal modals with our modals, this is a dirty solution
    if (window.vue && window.vue.$swal.isVisible()) {
      window.vue.$swal.close()
      return next(false)
    } else if (modal.items.length) {
      // Some modals, for example terms modal can't close by clicking the back button
      if (modal.items.length === 1 && modal.items[0].data && modal.items[0].data.cantCloseByBackButton) {
        return next(false)
      }
      modal.closeTop()
      return next(false)
    }
  }

  // We can change the title of the page
  let title = 'Dienstleisterportal'
  if (to.meta.title !== undefined) {
    title += ' | ' + to.meta.title
  }
  document.title = title

  document.body.style.overflow = ''

  // We should show a loading bar on the top
  events.fire('route@beforeEach')
  window.nprogress.start()

  if (auth.loggedIn) {
    window.waitForIt(() => {
      return auth.user && auth.user.currentWorkspaceWorkers
    }, () => {
      if (!auth.user.currentWorkspaceWorkers.find(o => o.id === auth.user.id).has_license) {
        modal.open({
          component: () => import('@/views/workspace/modals/WorkspaceDeactivated.vue'),
          classes: {
            dialog: 'modal-full-screen modal-workspace-alert',
            content: 'modal-wizard'
          },
          data: {
            cantCloseByBackButton: true,
            modalType: 'fullscreen'
          }
        })
        next({ name: 'home' })
      }
    })
  }

  if (to.meta.guest !== true && auth.loggedIn === false) {
    next({ name: 'login', params: { path: to.fullPath } })
  } else if (
    to.matched.some(o => o.path === '/auth') &&
    auth.loggedIn === true &&
    !to.matched.some(o => o.path.includes('business'))) {
    next({ name: 'home' })
    events.fire('route@afterEach')
    window.nprogress.done()
  } else if (to.meta.guest === true && auth.loggedIn && auth.expire_at) {
    next({ name: 'home' })
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  // We should reset the status
  window.popStateDetected = false

  // We should clear this value for side menu links
  auth.sideMenuCode = null

  // We are done!
  window.nprogress.done()

  const data = {
    name: to.name,
    full_path: to.fullPath,
    query: to.query,
    params: to.params
  }
  // We should notify to sub menu after change
  events.fire('route@afterEach')
  events.fire('route@updated', data)

  // We should send the route changes to the websocket
  // We can open this feature maybe lates.
  // socket.send('route@update', data)
})

export default router
