import cache from '@/core/cache'

export default {
  success (message, title = 'Erfolgreich', obj = {}) {
    // TODO: use and test JSON.deepAssign(...)
    return window.vue.$swal.fire(Object.assign({}, {
      title:
      '<img class="sweet-alert2__icon success" src="/images/swal-icons/swal-success.svg">' +
      '<span class="sweet-alert2__title">' +
        title +
      '</span>',
      html: message,
      icon: false,
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn btn sweet-alert2__confirm-btn no-margin'
      }
    }, obj))
  },

  error (message, title = 'Fehler') {
    window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon danger" src="/images/swal-icons/swal-danger.svg">' +
      '<span class="sweet-alert2__title">' +
        title +
      '</span>',
      html: message,
      icon: false,
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn btn sweet-alert2__confirm-btn no-margin'
      }
    })
  },

  info (message) {
    this.message('info', 'Information', message)
    window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon info" src="/images/swal-icons/swal-info.svg">' +
      '<span class="sweet-alert2__title">' +
        'Information' +
      '</span>',
      html: message,
      icon: false,
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn btn sweet-alert2__confirm-btn no-margin'
      }
    })
  },

  warning (message) {
    this.message('warning', 'Warnung', message)
    window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon warning" src="/images/swal-icons/swal-warning.svg">' +
      '<span class="sweet-alert2__title">' +
        'Warnung' +
      '</span>',
      html: message,
      icon: false,
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn btn sweet-alert2__confirm-btn no-margin'
      }
    })
  },

  unsavedData (save, cancel) {
    window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon new-change-confirmation" src="/images/swal-icons/swal-mission.svg">' +
      '<span class="sweet-alert2__title">' +
        'Ungespeicherte Daten' +
      '</span>',
      text: 'Es gibt noch Daten, die nicht gespeichert wurden. Wollen Sie diese Speichern?',
      icon: false,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Speichern',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header are-you-sure',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn btn sweet-alert2__confirm-btn',
        cancelButton: 'btn sweet-alert2__cancel-btn'
      }
    }).then((result) => {
      if (result.value) {
        save()
      } else {
        cancel()
      }
    })
  },

  onInNewChangeConfirmation (title, callback) {
    window.vue.$swal.fire({
      title: `<img class="sweet-alert2__icon warning" src="/images/swal-icons/swal-warning.svg"><span class="sweet-alert2__title">${title}</span>`,
      text: 'Diese Änderung wirkt sich auf alle zukünftigen Vorgänge aus. Vergangene Vorgänge werden nicht berücksichtigt.',
      icon: false,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Bestätigen',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal new-change-confirmation',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn sweet-alert2__confirm-btn',
        cancelButton: 'btn sweet-alert2__cancel-btn'
      }
    }).then((result) => {
      if (result.value) {
        callback()
      }
    })
  },

  successRemember (message, title = 'Erfolgreich', cb, obj = {}) {
    if (cache.getStored('remember_convert.' + title, () => 'false') === 'true') {
      return cb()
    }
    this.success(message, title, obj).then(res => {
      cb()
    })
  },

  areYouSure (message, callback) {
    window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon warning" src="/images/swal-icons/swal-warning.svg">' +
      '<span class="sweet-alert2__title">' +
        'Achtung' +
      '</span>',
      html: message,
      icon: false,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Ja',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn sweet-alert2__confirm-btn',
        cancelButton: 'btn sweet-alert2__cancel-btn'
      }
    }).then((result) => {
      if (result.value) {
        callback()
      }
    })
  },

  areYouSureCheckbox (message, inputPlaceholder, callback) {
    window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon warning" src="/images/swal-icons/swal-warning.svg">' +
      '<span class="sweet-alert2__title">' +
        'Achtung' +
      '</span>',
      html: message,
      icon: false,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Ja',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      input: 'checkbox',
      inputValue: 1,
      inputPlaceholder,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn sweet-alert2__confirm-btn',
        cancelButton: 'btn sweet-alert2__cancel-btn'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result)
      }
    })
  },

  delete (message, callback) {
    window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon danger" src="/images/swal-icons/swal-danger.svg">' +
      '<span class="sweet-alert2__title">' +
        'Löschen' +
      '</span>',
      html: message,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Löschen',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn sweet-alert2__confirm-btn danger',
        cancelButton: 'btn sweet-alert2__cancel-btn'
      }
    }).then((result) => {
      if (result.value) {
        callback()
      }
    })
  },

  message (type, title, message) {
    window.vue.$swal.fire({
      icon: type,
      title: title,
      html: message
    })
  },

  messageHtml (type, title, message) {
    window.vue.$swal.fire({
      icon: type,
      title: title,
      html: message
    })
  }

}
