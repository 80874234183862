import ServerCache from '@/plugins/ServerCache'
import firstTimeModals from '@/core/firstTimeModals'
import rememberMeModals from '@/core/rememberMeModals'
import modalBase from '@/core/modalBase'

const modal = {
  ...modalBase,

  async openRememberMe (uuid, options = { bindings: {} }, cb = () => {}) {
    const data = rememberMeModals.find(o => o.uuid === uuid)
    if (await ServerCache.get('rm.' + uuid) === true) {
      return cb()
    }

    this.open({
      component: () => import('@/components/CustomModal.vue'),
      classes: {
        mainClass: 'modal-gtk',
        // dialog: 'modal-alert-like-dialog',
        dialog: 'modal-alert-like-dialog first-time modal-alert-gtk modal-450',
        // content: 'modal-alert-like'
        content: 'modal-alert-gtk'
      },
      data: {
        uuid,
        ...data,
        ...options
      },
      onClose: async (remember, force = true) => {
        if (remember && force) {
          await ServerCache.set('rm.' + uuid, remember)
        }

        if (typeof cb === 'function' && force) {
          cb()
        }
      }
    })
  },

  async openFirstTime (uuid, cb = () => {}) {
    const data = firstTimeModals.find(o => o.uuid === uuid)
    if (!data) {
      throw Error(uuid + ' not found!')
    }
    if (await ServerCache.get('ftm.' + uuid)) {
      return cb()
    }
    await ServerCache.set('ftm.' + uuid, true)
    this.open({
      component: () => import('@/components/FirstTimeModal.vue'),
      classes: {
        dialog: 'modal-alert-like-dialog first-time',
        content: 'modal-alert-like first-time'
      },
      data,
      onClose: () => {
        cb()
      }
    })
  }
}

export default modal
