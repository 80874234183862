import { v4 as uuid } from 'uuid'

export default {

  events: {},
  prematureFires: {},

  listen (name, callback) {
    if (this.events[name] === undefined) {
      this.events[name] = []
    }
    const id = uuid()
    this.events[name].push({
      uuid: id,
      callback: callback
    })

    // If there is any premature fires, before lister, we should trigger them.
    if (this.prematureFires[name]) {
      this.prematureFires[name].forEach((data) => {
        try {
          callback(data)
        } catch (error) {
          if (window.Sentry) {
            window.Sentry.captureException(error, { name, data })
          }
        }
      })
      delete this.prematureFires[name]
    }

    return id
  },

  replace (name, callback) {
    this.clear(name)
    this.listen(name, callback)
  },

  clear (name) {
    this.events[name] = []
  },

  clearById (name, uuid) {
    if (this.events[name] === undefined) {
      return
    }

    this.events[name] = this.events[name].filter(item => item.uuid !== uuid)
  },

  listenOnce (name, callback) {
    if (this.has(name) === false) {
      this.listen(name, callback)
    }
  },

  has (name) {
    return this.events[name] !== undefined
  },

  addPrematureFires (name, data) {
    if (this.prematureFires[name] === undefined) {
      this.prematureFires[name] = []
    }

    this.prematureFires[name].push(data)
  },

  fire (name, data) {
    if (this.events[name] === undefined) {
      this.addPrematureFires(name, data)
      return null
    }

    this.events[name].forEach(item => {
      item.callback(data)
    })
  }

}
