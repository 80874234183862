JSON.clone = function (data) {
  if (data) {
    return JSON.parse(JSON.stringify(data))
  }
  return null
}
JSON.value = (item) => {
  if (item === undefined || item === null) {
    return null
  }
  if (item.value === undefined) {
    return null
  }
  return item.value
}

JSON.selectId = function (value) {
  if (value === undefined || value === null) {
    return null
  }
  return value.id ? value.id : null
}

JSON.uniqueById = function (arr, key) {
  if (!arr) {
    return null
  }
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

function toType (a) {
  // Get fine type (object, array, function, null, error, date ...)
  return ({}).toString.call(a).match(/([a-z]+)(:?\])/i)[1]
}

function isDeepObject (obj) {
  return toType(obj) === 'Object'
}

JSON.deepAssign = function (options) {
  return function deepAssignWithOptions (target, ...sources) {
    sources.forEach((source) => {
      if (!(source) || !isDeepObject(target)) { return }

      // Copy source's own properties into target's own properties
      function copyProperty (property) {
        const descriptor = Object.getOwnPropertyDescriptor(source, property)
        if (descriptor.enumerable || options.nonEnum) {
          if (isDeepObject(source[property]) && isDeepObject(target[property])) {
            descriptor.value = JSON.deepAssign(options)(target[property], source[property])
          }
          if (options.descriptors) {
            Object.defineProperty(target, property, descriptor)
          } else {
            target[property] = descriptor.value
          } // shallow copy value only
        }
      }

      // Copy string-keyed properties
      Object.getOwnPropertyNames(source).forEach(copyProperty)

      // default: omit symbol-keyed properties
      if (options.symbols) { Object.getOwnPropertySymbols(source).forEach(copyProperty) }

      // default: omit prototype's own properties
      if (options.proto) {
        JSON.deepAssign(Object.assign({}, options, { proto: false }))(// Prevent deeper copy of the prototype chain
          Object.getPrototypeOf(target),
          Object.getPrototypeOf(source)
        )
      }
    })
    return target
  }
}
