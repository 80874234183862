const HashTracker = {
  initial: null,
  current: null,
  method: null,
  onRelease: null,
  config: {},

  clear () {
    HashTracker.initial = null
    HashTracker.current = null
    HashTracker.method = null
    HashTracker.onRelease = null
    HashTracker.config = {}
  },

  release (hash) {
    if (hash) {
      HashTracker.initial = hash
    }

    if (typeof HashTracker.onRelease === 'function') {
      HashTracker.onRelease()
    }
  },

  setRelease (onRelease) {
    HashTracker.onRelease = onRelease
  },

  setSaveMethod (method, config = {}) {
    HashTracker.method = method
    HashTracker.config = config
  },

  setInitial (hash) {
    HashTracker.initial = hash
  },

  setCurrent (hash) {
    HashTracker.current = hash
  },

  askConfirmation (save, cancel, discard) {
    window.vue.$swal.fire(Object.assign({}, {
      title:
      '<img class="sweet-alert2__icon warning" src="/images/swal-icons/swal-warning.svg">' +
      '<span class="sweet-alert2__title">' +
        'Änderungen wurden nicht veröffentlicht' +
      '</span>',
      text: 'Ihre Änderungen werden verworfen, falls Sie die Seite verlassen. Möchten Sie wirklich fortfahren?',
      icon: false,
      showCancelButton: true,
      showCloseButton: true,
      showDenyButton: true,
      denyButtonText: 'Seite trotzdem verlassen und Änderungen verwerfen',
      cancelButtonText: 'Zurück zur Bearbeitung',
      confirmButtonText: 'Änderungen speichern',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      animation: false,
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal ask-confirmation',
        header: 'sweet-alert2__header are-you-sure',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        cancelButton: 'btn sweet-alert2__cancel-btn ask-confirmation',
        confirmButton: 'btn sweet-alert2__confirm-btn ask-confirmation',
        denyButton: 'btn sweet-alert2__deny-btn ask-confirmation'
      }
    }, HashTracker.config)).then((result) => {
      if (result.isDenied) {
        discard()
      } else if (result.isConfirmed) {
        save()
      } else {
        cancel()
      }
    })
  }
}

export default HashTracker
