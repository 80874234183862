const rememberMeModals = [
  {
    uuid: 'convert_offer_to_order',
    title: 'Gut zu wissen',
    src: 'images/helper-modal/offer.svg',
    htmltext: `
    <div class="modal-alert-like__paragraph">
      Die mit diesem Angebot verknüpfte Anfrage <router-link :to="{{toParams}}" @click.native="close">{{custom_id}}</router-link>
      wurde ebenfalls auf den Status <strong>Auftrag erhalten</strong> gesetzt.
    </div>
    `
  },
  {
    uuid: 'convert_deal_offer_order',
    title: 'Gut zu wissen',
    src: 'images/helper-modal/offer-to-won.svg',
    htmltext: `
      <div class="modal-alert-like__paragraph">
      Das mit dieser Anfrage verknüpfte Angebot <router-link :to="{{toParams}}" @click.native="close">{{custom_id}}</router-link>
      wurde auf den Status "Erhalten" gesetzt.
      </div>
    `
  },
  {
    uuid: 'state_change_to_contacted',
    title: 'Gut zu wissen',
    src: 'images/helper-modal/state-change.svg',
    htmltext: `
      <div class="modal-alert-like__paragraph">
      Der Status der Anfrage wurde automatisch auf <strong>Kontaktiert</strong> gesetzt,
      da Sie ein <strong>ausgehendes Ereignis hinzugefügt</strong> haben.
      </div>
      `,
    okButton: 'Prima'
  },
  {
    uuid: 'state_change_to_appointment',
    title: 'Gut zu wissen',
    src: 'images/helper-modal/state-change-appointment.svg',
    htmltext: `
      <div class="modal-alert-like__paragraph">
      Der Status der Anfrage wurde automatisch auf <strong>Termin vereinbart</strong> gesetzt,
      da Sie ein <strong>Termin erstellt</strong> haben.
      </div>
      `,
    okButton: 'Prima'
  },
  {
    uuid: 'state_change_to_offer',
    title: 'Gut zu wissen',
    src: 'images/helper-modal/offer-created.svg',
    htmltext: `
      <div class="modal-alert-like__paragraph">
      Der Status der Anfrage wurde automatisch auf <strong>Angebot erstellt</strong> gesetzt,
      da Sie ein <strong>Angebot erstellt</strong> haben.
      </div>
      `,
    okButton: 'Prima'
  },
  {
    uuid: 'cancel_offer_deal',
    title: 'Gut zu wissen',
    src: 'images/helper-modal/reset-lost-angebot.svg',
    htmltext: `
      <div class="modal-alert-like__paragraph">
      Die verknüpfte Anfrage <router-link :to="{{toParams}}" @click.native="close">{{custom_id}}</router-link> wurde auf <strong>Storniert</strong> gesetzt.
      </div>
      `,
    okButton: 'Prima'
  },
  {
    uuid: 'convert_deal_to_order',
    title: 'Auftrag erfolgreich erstellt',
    src: 'images/helper-modal/generates-order.svg',
    htmltext: `
      <div class="modal-alert-like__paragraph">
      Auftrag mit der Auftragsnummer <strong>{{custom_id}}</strong> wurde erfolgreich erstellt.
      </div>
      `,
    okButton: 'Okay'
  },
  {
    uuid: 'cancel_deal_offer',
    title: 'Gut zu wissen',
    src: 'images/helper-modal/deal-offer-cancel.svg',
    htmltext: `
      <div class="modal-alert-like__paragraph">
      Das verknüpfte Angebot <router-link :to="{{toParams}}" @click.native="close">{{custom_id}}</router-link> wurde auf <strong>Abgelehnt</strong>
      gesetzt.
      </div>
      `
  },
  {
    uuid: 'reset_offer',
    title: 'Gut zu wissen',
    src: 'images/helper-modal/delete-order.svg',
    htmltext: `
      <div class="modal-alert-like__paragraph">
      Die verknüpfte Anfrage <router-link :to="{{toParams}}" @click.native="close">{{custom_id}}</router-link> wurde ebenfalls <strong>zurückgesetzt</strong>.
      </div>
      `,
    okButton: 'Okay'
  },
  {
    uuid: 'deal_resetted',
    title: 'Gut zu wissen',
    src: 'images/helper-modal/delete-order.svg',
    htmltext: `
      <div class="modal-alert-like__paragraph">
      Die verknüpfte Anfrage <router-link :to="{{toParams}}" @click.native="close">{{custom_id}}</router-link> wurde <strong>zurückgesetzt</strong>.
      </div>
      `,
    okButton: 'Okay'
  },
  {
    uuid: 'deal_and_offer_resetted',
    title: 'Gut zu wissen',
    src: 'images/helper-modal/delete-order.svg',
    htmltext: `
      <div class="modal-alert-like__paragraph">
      Die verknüpfte Anfrage <router-link :to="{{toParams}}" @click.native="close">{{custom_id}}</router-link> und das Angebot <router-link :to="{{toParams2}}" @click.native="close">{{custom_id2}}</router-link> wurden <strong>zurückgesetzt</strong>.
      </div>
      `,
    okButton: 'Okay'
  },
  {
    uuid: 'offer_resetted',
    title: 'Gut zu wissen',
    src: 'images/helper-modal/delete-order.svg',
    htmltext: `
      <div class="modal-alert-like__paragraph">
      Das verknüpfte Angebot <router-link :to="{{toParams}}" @click.native="close">{{custom_id}}</router-link> wurde <strong>zurückgesetzt</strong>.
      </div>
      `,
    okButton: 'Okay'
  },
  {
    uuid: 'work_resetted',
    title: 'Achtung',
    src: 'images/helper-modal/delete-order.svg',
    htmltext: `
      <div class="modal-alert-like__paragraph">
      Wollen Sie {{work}} wirklich zurücksetzen?
      </div>
      `,
    okButton: 'Zurücksetzen',
    secondaryButton: 'Abbrechen'
  }
]

export default rememberMeModals
