import axios from 'axios'

const Loader = {
  items: {
    buildingStandards: null,
    buildyearTypes: null,
    lagebewertung: null,
    matchingTable: null,
    modernizationCostsAll: null,
    modernizationCostsAprox: null,
    modernizationCostsDetailed: null,
    objektToMDC: null,
    objektTypeMap: null,
    standardLevels: null,
    waegungsanteile: null,
    zustandData: null
  },

  async loadAll () {
    return new Promise((resolve) => {
      axios.all([
        Loader.load('buildingStandards'),
        Loader.load('buildyearTypes'),
        Loader.load('lagebewertung'),
        Loader.load('matchingTable'),
        Loader.load('modernizationCostsAll'),
        Loader.load('modernizationCostsAprox'),
        Loader.load('modernizationCostsDetailed'),
        Loader.load('objektToMDC'),
        Loader.load('objektTypeMap'),
        Loader.load('standardLevels'),
        Loader.load('waegungsanteile'),
        Loader.load('zustandData')
      ]).then(resolve)
    })
  },

  async load (name) {
    if (Loader.items[name]) {
      return Loader.items[name]
    }

    return new Promise((resolve) => {
      axios.get(`/project-datas/${name}.json`).then((response) => {
        Loader.items[name] = response.data
        resolve(Loader.items[name])
      })
    })
  }
}

export const all = () => {
  return Loader.items
}

export const globalDataProject = (name) => {
  return Loader.items[name]
}

export default Loader
