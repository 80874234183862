window.uniqueId = function (data = []) {
  const id = Date.now() + Math.random().toString(32).slice(-8)
  return (data.some(o => o.id === id)) ? window.uniqueId(data) : id
}

window.waitForCond = async function (cond, timeoutms, interval = 50) {
  return new Promise((resolve, reject) => {
    const check = () => {
      if (cond()) {
        resolve(true)
      } else if ((timeoutms -= interval) < 0) {
        // eslint-disable-next-line prefer-promise-reject-errors
        resolve(false)
      } else {
        setTimeout(check, interval)
      }
    }
    setTimeout(check, interval)
  })
}

window.waitForIt = (cond, resolve) => {
  let timeoutms = 5000
  const check = () => {
    if (cond()) {
      resolve(true)
    } else if ((timeoutms -= 50) < 0) {
      // do-nothing
    } else {
      setTimeout(check, 50)
    }
  }
  setTimeout(check, 50)
}

window.capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

window.hasAdblock = async () => {
  var hasAdblock = false
  var testAd = document.createElement('div')
  testAd.innerHTML = '&nbsp;'
  testAd.className = 'ads ad adsbox doubleclick ad-placement carbon-ads'
  document.body.appendChild(testAd)
  await new Promise(resolve => {
    setTimeout(() => {
      testAd.offsetHeight === 0 && (hasAdblock = true)
      testAd.remove()
      resolve()
    }, 100)
  })
  return hasAdblock
}

window.oSome = (object, field, value) => {
  let result = false
  for (const key of Object.keys(object)) {
    if (object[key][field] === value) {
      result = true
    }
  }
  return result
}

window.oFind = (object, field, value) => {
  let result
  for (const key of Object.keys(object)) {
    if (object[key][field] === value) {
      result = object[key]
      break
    }
  }
  return result
}

window.oFindIndex = (object, field, value) => {
  let result
  for (const key of Object.keys(object)) {
    if (object[key][field] === value) {
      result = key
      break
    }
  }
  return result
}

window.wait = (time) => {
  return new Promise(resolve => setTimeout(resolve, time))
}
