import { v4 as uuid } from 'uuid'

const modal = {
  items: [],

  close (uuid, param, param2) {
    const idx = this.items.findIndex(modal => modal.uuid === uuid)
    if (idx !== -1) {
      this.items[idx].onClose(param, param2)
      this.items.splice(idx, 1)
      this.setBodyClasses()
    }
  },

  setBodyClasses () {
    document.body.className = this.items.length > 0 ? 'modal-open' : ''
  },

  open (modal) {
    modal.uuid = uuid()

    const item = Object.assign({
      component: null,
      size: 'md',
      data: {},
      classes: {
        content: null
      },
      events: {
        onSelected: () => {}
      },
      onClose: () => {}
    }, modal)
    this.items.push(item)
    this.setBodyClasses()

    return modal
  },

  closeTop () {
    if (this.items.length) {
      this.close(this.items[this.items.length - 1].uuid)
    }
  }
}

export default modal
